<template>
    <div class="template-page">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
            

            <h2 class="title-section" v-if="this.ongoingCase.length>0">
                Ongoing Case Studies
            </h2>
            <div v-for="(study, index) in this.ongoingCase" class="item-case angle yellow-angle" :key="'o'+index">
                <div class="image" >
                    <img v-if="study.field_casestudy_logo" :src="imageCase + study.field_casestudy_logo.attributes.uri.url" alt="">
                </div>
                <div class="text">
                    <h2>{{study.attributes.title}}</h2>
                    <p v-html="study.attributes.field_casestudy_description.value"></p>
                    <br>
                    <a :href="study.attributes.field_casestudy_website.uri" target="_blank" class="cta-link"> <span>Go to website</span> </a>
                </div>
            </div>


            <h2 class="title-section" v-if="this.pastCase.length>0">
                Past Case Studies
            </h2>
            <div v-for="(study, index) in this.pastCase" class="item-case angle yellow-angle" :key="'p'+index">
                <div class="image">
                    <img v-if="study.field_casestudy_logo" :src="imageCase + study.field_casestudy_logo.attributes.uri.url" alt="">
                </div>
                <div class="text">
                    <h2>{{study.attributes.title}}</h2>
                    <p v-html="study.attributes.field_casestudy_description.value"></p>
                </div>
            </div>

        </basic-page>
    </div>
</template>
<script>
import { fetchSingleNode,fetchNodes } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'case-studies',
    data: () => {
        return {
            currentPageId: "b1cd924c-0dc3-4d96-8a50-488a51ca9750",
            pageData: {},
            fulldata: {},
            caseStudies:[],
            pastCase:[],
            ongoingCase:[],
            bannerimg: null
        }
    },
    computed: {
        imageCase() {
            return process.env.VUE_APP_ENDPOINT
        }
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })

        fetchNodes('case_study', {
                include: ['field_casestudy_logo']
            })
            .then(res => {
                //console.log('res',res);
                this.caseStudies = res

                this.caseStudies.forEach(i=>{
                    if(i.attributes.field_casestudy_status == 1){
                        this.ongoingCase.push(i)
                        //console.log('this.ongoingCase ',this.ongoingCase);
                    } else {
                        this.pastCase.push(i)
                    }

                })
                
            })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";



.item-case {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 30px 0px 30px 0px;
    .image::before{
        display: none;
    }
    .image {
        width: 25%;
        min-width: 25%;
        padding: 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            object-fit: contain;
            max-height: 200px;
            width: 100%;
            height: 100%;
        }
    }
    .text {
        width: 70%;
        padding: 0px 50px 20px 50px;
    }
}

.item-case.angle::after {
    right: .5em;
}

.item-case .cta-link {
    max-width: 170px;
}


@media (max-width:1024px) {
    .item-case:not(:last-child) {
        border-bottom: 1px solid rgba(17, 35, 66, .3);
    }
    
    .item-case{
        padding-top: 10px;
        flex-direction: column;
        margin: 15px 0px;
        .text {
                padding: 15px 0px;
                width: 100%;
        } 
        .image {
            width: 100%;
            img {
                width: auto;
                max-width: 100%;
            }
        }
    }
}
</style>